import Page from "./system/_convinceMyBossPage";

export default Page;

export const frontmatter = {
  pageMetaData: {
    refKey: "convince-my-boss",
    title: 'The Ultimate "Convince my Boss" guide for backups!',
    description:
      "Learn how to pitch your team about why you need to backup your web applications, databases, servers (...)" +
      "Thorough guide to help you convince your boss to invest in backups with stats, how-to, best-practices and " +
      "walkthrough.",
  },
};
