import React, { useState, useRef, useEffect } from "react";
import * as Ui from "components/ui";
import * as Layout from "components/layout";
import { loginUrl } from "../../utils/urlHelper";

const convincingContexts = Object.freeze({
  "tech-founder": "The Tech founder",
  "agency-owner": "The Agency owner",
  "saas-app": "The SaaS App case (Notion, Knack, Git..)",
});

const ConvinceMyBossPage = () => {
  const [context, setContext] = useState("tech-founder");

  const shownFor = (...contexts) => {
    return !contexts.includes(context) ? "hidden" : "";
  };
  const [visibleId, setVisibleId] = useState(null);
  const targetRefs = useRef({});

  // Define your IDs for easy mapping
  const targetIds = [
    "understanding-baas",
    "data-security-and-compliance",
    "discover-simplebackups",
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        // Check the entries to find the last one that is intersecting
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Only update if the new ID is different from the current one
            if (entry.target.id !== visibleId) {
              setVisibleId(entry.target.id);
            }
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 1, // Adjust based on your needs
      }
    );

    // Observe each target element
    targetIds.forEach((id) => {
      const target = targetRefs.current[id];
      if (target) {
        observer.observe(target);
      }
    });

    return () => {
      // Unobserve on cleanup
      targetIds.forEach((id) => {
        const target = targetRefs.current[id];
        if (target) {
          observer.unobserve(target);
        }
      });
    };
  }, [targetIds, visibleId]);

  return (
    <Layout.Layout className="relative">
      <Layout.SEO refKey="convince-my-boss" />
      <Layout.HeroEmpty></Layout.HeroEmpty>

      <Ui.Section color="primary">
        <Ui.Container>
          <Ui.Row>
            <div className="col-md-12 text-center mb-16">
              <h1 className="font-heading text-4xl md:text-5xl leading-tight font-black text-dark">
                The ultimate "Convince my boss" Guide for backups!
              </h1>
              <p>
                You're in charge of defining what backup solution to use, and
                we'll help you convince whoever signs at the bottom!
              </p>
            </div>
            <TableOfContents />
          </Ui.Row>
        </Ui.Container>
      </Ui.Section>
      {/* {AIGenerator()} */}
      {ElevatorPitch(context, setContext, shownFor)}
      {StickyNavigation(context, setContext, visibleId)}

      <a
        id="understanding-baas"
        ref={(el) => (targetRefs.current["understanding-baas"] = el)}
      ></a>
      {WhyYouNeedABackupSolution()}
      {UnderstandWhatBaasIsAndWhyYouMightNeedIt()}
      {YourObligationsWhenItComesToBackup(context, shownFor)}

      <a
        id="data-security-and-compliance"
        ref={(el) => (targetRefs.current["data-security-and-compliance"] = el)}
      ></a>
      {DataSecurityWithSimpleBackups()}

      <a
        id="discover-simplebackups"
        ref={(el) => (targetRefs.current["discover-simplebackups"] = el)}
      ></a>
      {SimpleBackupsWalkthrough()}
      {RealWorldUseCases(context)}
    </Layout.Layout>
  );
};

export default ConvinceMyBossPage;
function ElevatorPitch(context, setContext, shownFor) {
  return (
    <Ui.Section className="bg-aubergine text-white ">
      <Ui.Container>
        <Ui.Row>
          <div className="lg:grid lg:grid-cols-3 lg:gap-8">
            <div>
              <h2 className="text-3xl font-extrabold" id="elevator-pitch">The Elevator Pitch</h2>
              <p className="mt-4 text-lg text-warm-gray-500">
                <div className="uppercase text-xs text-gray-400 tracking-wide">
                  Select your Topic
                </div>
                <select
                  value={context}
                  onChange={(e) => setContext(e.target.value)}
                  className="mt-2 block rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                >
                  {Object.keys(convincingContexts).map((k) => (
                    <option value={k} key={k}>
                      {convincingContexts[k]}
                    </option>
                  ))}
                </select>
              </p>
            </div>

            <div className="mt-12 lg:mt-0 lg:col-span-2 z-50">
              <dl
                className={`space-y-12 text-base border-l-2 border-gray-100 pl-12 ${shownFor(
                  "tech-founder"
                )}`}
              >
                You're working in a startup, a SaaS maybe, a marketplace, in any
                case you're dealing with databases, code, and a lot of data.
                <br />
                <br />
                Well, why would you need a backup solution?
                <br />
                You have configured your AWS daily backups, and even-though it
                cost you a lot of sweat it's now running so, no need right...?
                <br />
                <br />
                Well it depends.
                <br />
                <br />
                <span className="border-b border-dotted border-gray-50">
                  Do you know if you backups are really running?
                </span>
                <br />
                <span className="border-b border-dotted border-gray-50">
                  Have you checked if you can restore them?
                </span>
                <br />
                I guess someone from your team is regularly testing these
                backups .. right?
                <br />
                Btw, do you know if restoring them means restoring your whole
                server, must be documented... right?
                <br />
                <br />
                Also,{" "}
                <span className="border-b border-dotted border-gray-50">
                  have you considered what would happen if your AWS account was
                  compromised?
                </span> <br/>What if your lead dev, leaves the company and you need to restore a backup?
                <br />
                <span className="border-b border-dotted border-gray-50">
                  Ever heard about RansomWare?
                </span>
                <br />
                <br />
                <span className="border-b border-dotted border-gray-50">
                  Are you GDPR compliant? What about your backups?
                </span>
                <br />
                Did Marco tell you that the next ISO certification renewal is in
                2 weeks?<br/>
                <span className="border-b border-dotted border-gray-50">
                  Do you have the proper documentation and logs for your
                  security audit?
                </span>
                <br />
                <br />
                What if I told you, you could check all of these marks with a
                single solution?
                <br />
                Well, you may want to consider a Backup-as-a-Service (BaaS) solution.
                <br />
                <br />
                Run your backups exactly when you want, store them in multiple
                locations cross-providers.
                <br />
                Get notified on any channels when something goes wrong, and have
                a clear and documented process.
                <br />
                Have a sharable dashboard with all your backups, and a clear
                audit trail for you, your team and your customers if needed.
                <br />
                Tell your team you can trigger your backup from a simple trigger
                URL or even embed it in their CI system and see what a smiling
                tech team can do!
                <br />
                And most importantly, have a solution that will help you sleep
                at night, knowing that your data is safe and that you can
                recover it in a few clicks, without required a doctorate in AWS
                UX.
                <br />
                <br />
                Ding, 7th floor, have a good day.
              </dl>

              <dl
                className={`space-y-12 text-base border-l-2 border-gray-100 pl-12 ${shownFor(
                  "agency-owner"
                )}`}
              >
                Your time is short, a big prospect is waiting for you in the
                conference room to hear about your pitch for that app you can
                develop for them and you'll have to make the best impression, I
                get it!
                <br />
                <br />
                Well, let's start with that then!
                <br />
                This customer is betting on this project, I guess he won't be
                against an insurance that will cover him in case anything goes
                wrong right? He obviously heard about ransomware and
                even-thought he knows you're the best, a guarantee on what will
                become is core activity will be appreciated.
                <br />
                <br />
                Think about it... would your client prefer a company that comes
                with a solid backup solution, and disaster recovery plan or a
                company that will say, well there's a daily backup snapshot
                running on your provider.
                <br />
                <br />
                I bet you they'll trust you more and even pay more for it!
                <br />
                <br />
                The other side of the coin is that your tech team as other fish
                to fry, than setting up backup for each databases of each
                project, confirming they're running well, making sure there is
                enough space on your storage and that for any reason you don't
                start overpaying for it.
                <br />
                <br />
                Ask them!
                <br />
                <span className="border-b border-dotted border-gray-50">
                  Do you have a real backup in place for all the projects you
                  manage?
                </span>
                <br />
                By real, I don't mean a snapshot, I mean something usable in
                case there is a real issue, something you can restore on another
                system if your account is compromised.
                <br />
                If so,{" "}
                <span className="border-b border-dotted border-gray-50">
                  how do you know it's running well
                </span>
                ,{" "}
                <span className="border-b border-dotted border-gray-50">
                  how do you know you can restore it?
                </span>
                <br />
                <span className="border-b border-dotted border-gray-50">
                  Who's taking care of these restore btw?
                </span>
                <br />
                <span className="border-b border-dotted border-gray-50">
                  Are you sharing this with your team and not just the lead dev,
                  and does your customer knows?
                </span>
                <br />
                <br />
                Ding, 7th floor, have a good day.
              </dl>
              <dl
                className={`space-y-12 text-base border-l-2 border-gray-100 pl-12 ${shownFor(
                  "saas-app"
                )}`}
              >
                SaaS Apps are great!
                <br />
                You get a great app, and you don't have to deal with product
                updates, infrastructure, security, and even your data.
                <br />
                <br />
                Your entire business is now relying on these apps, you may have
                all your SOPs, your processes, databases, or even your CRM in
                there.
                <br />
                <br />
                Ask yourself the question:{" "}
                <span className="border-b border-dotted border-gray-50">
                  what's the impact if my account is compromised, what if
                  something happens and I lose all my data?
                </span>
                <br />
                I don't want to flood you with numbers, but the average cost of
                a data breach is around 4M$, and ransomware is a real deal.
                Accounts get compromised and data gets lost.
                <br />
                <br />
                <span className="border-b border-dotted border-gray-50">
                  We're talking about you not being able to access your Notion
                  Workspace, your Airtable account, or even your Git
                  repositories.
                </span>
                <br />
                Well in 2024, for most businesses this would be a disaster.
                <br />
                <br />
                Now, what can you do? Easy, you can have a backup plan and own
                your data.
                <br />
                And you know what? SimpleBackups happen to build just that, an
                easy and automated way for you to backups all your SaaS data and
                store it in a safe place that YOU own.
                <br />
                <br />
                I won't try to convince you about SimpleBackups, but understand
                your risks and, please, have a plan.
                <br />
                <span className="border-b border-dotted border-gray-50">
                  If there is one take away from this conversation, it should be
                  "Own your data".
                </span>
                <br />
                <br />
                Ding, have a good day.
              </dl>
            </div>
          </div>
        </Ui.Row>
      </Ui.Container>
    </Ui.Section>
  );
}

function WhyYouNeedABackupSolution() {
  const dataType = {
    database: "Database (self-hosted)",
    "managed-database": "Managed Database",
    storage: "Storage (S3...)",
    git: "Git",
    "saas-application": "SaaS Application (general)",
  };

  const [dataT, setDataT] = useState("database");

  return (
    <Ui.Section color="alt">
      <Ui.Container>
        <Ui.Row>
          <div className="lg:grid lg:grid-cols-3 lg:gap-8">
            <div>
              <a id="WhyDoYouNeedABackupSolution">
                <h2 className="text-3xl font-extrabold">
                  Why do you need a backup solution?
                </h2>
              </a>

              <p className="mt-4 text-lg text-warm-gray-500">
                <div className="uppercase text-xs text-gray-400 tracking-wide">
                  Understanding BaaS
                </div>
              </p>
            </div>

            <div className="mt-12 lg:mt-0 lg:col-span-2">
              <dl className="space-y-12">
                <div className="grid md:grid-cols-12 md:gap-4 gap-2 text-left ">
                  <div className="col-span-4 flex items-center bg-white rounded-xl p-8">
                    <i className="fad fa-solid fa-circle-1 text-primary text-2xl mr-4" />
                    <h3 className="text-xl font-bold block">Data Loss</h3>
                  </div>
                  <div className="col-span-4 flex items-center bg-white rounded-xl p-8">
                    <i className="fad fa-solid fa-circle-2 text-primary text-2xl mr-4" />
                    <h3 className="text-xl font-bold block">Cyber Threat</h3>
                  </div>
                  <div className="col-span-4 flex items-center bg-white rounded-xl p-8">
                    <i className="fad fa-solid fa-circle-3 text-primary text-2xl mr-4" />
                    <h3 className="text-xl font-bold block">Service Outage</h3>
                  </div>
                </div>

                <div>
                  <dt className="text-lg font-bold" id="understand-threats">
                    Understand the threats and the risks you're facing
                  </dt>
                  <dd className="mt-2 text-base text-warm-gray-500">
                    <ul className="flex flex-col gap-4">
                      <li>
                        <span className="link-highlight">
                          <strong>Human error:</strong>
                        </span>{" "}
                        Nearly 40% of data loss is caused by human
                        mistakes—accidental deletions, misconfigurations, or
                        overwriting important files. Without backups, these
                        errors can lead to permanent data loss and costly
                        downtime.
                      </li>
                      <li>
                        <span className="link-highlight">
                          <strong>Technical issues:</strong>
                        </span>{" "}
                        Hardware failures and software glitches are responsible
                        for about 45% of unplanned downtime, leading to data
                        corruption or loss. A reliable backup ensures quick
                        recovery and minimizes disruption.
                      </li>
                      <li>
                        <span className="link-highlight">
                          <strong>Ransomware:</strong>
                        </span>{" "}
                        Ransomware attacks surged by 93% in 2023, with
                        businesses facing days or even weeks of downtime.
                        Backups can prevent paying ransom and ensure you recover
                        data without delays.
                      </li>
                      <li>
                        <span className="link-highlight">
                          <strong>Cyber Attack:</strong>
                        </span>{" "}
                        Cyberattacks target vulnerabilities to steal or destroy
                        data. With regular backups, you can restore your systems
                        quickly and avoid major financial or operational damage.
                      </li>
                      <li>
                        <span className="link-highlight">
                          <strong>Service Outage:</strong>
                        </span>{" "}
                        Cloud or SaaS platform outages are unpredictable and can
                        leave you without access to critical data. Local or
                        alternative backups guarantee you stay operational, even
                        during service interruptions.
                      </li>
                    </ul>
                  </dd>
                </div>

                <div>
                  <dt className="text-lg font-bold" id="why-you-to-backup">
                    Why you need to backup your data (per data type)
                  </dt>
                  <dd className="mt-4 text-base text-warm-gray-500">
                    Select the type of data you're willing to backup, and we'll
                    provide you with a list of reasons why we think you should
                    back them up on top of the general reasons mentioned above.
                    <div className="block mt-2">
                      <select
                        value={dataT}
                        onChange={(e) => setDataT(e.target.value)}
                        className="mt-2 block rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      >
                        <option value={"database"}>
                          Database (self-hosted)
                        </option>
                        <option value={"managed-database"}>
                          Managed Database
                        </option>
                        <option value={"storage"}>Storage (S3...)</option>
                        <option value={"git"}>Git</option>
                        <option value={"saas-application"}>
                          SaaS Application (general)
                        </option>
                      </select>

                      <div
                        className={`${
                          dataT == "database" || dataT == "managed-database"
                            ? "mt-4"
                            : "hidden"
                        } bg-white shadow sm:rounded-lg max-w-xl`}
                      >
                        <div className="px-4 py-5 sm:p-6">
                          <h3 className="text-base font-semibold leading-6 text-gray-900">
                            Why you should backup your "Managed Databases"
                          </h3>
                          <div className="mt-2 text-sm text-gray-500">
                            <p>
                              Managed databases are, by definition, managed by a
                              third-party provider. This is beneficial because
                              you gain access to services that typically require
                              a DevOps or DBA specialist, such as instance
                              scaling, security patching, and even basic backup
                              procedures.
                              <br />
                              <br />
                              <span className="link-highlight">
                                However, these solutions alone won't fully
                                protect you from human error, accidental
                                deletion, data corruption, ransomware, or other
                                cyberattacks.
                              </span>
                              <br />
                              <br />
                              <ul className="ml-4 gap-1 flex flex-col">
                                <li>
                                  ✅ Own your backups, store them in a separate
                                  location
                                </li>
                                <li>✅ Run backups when you need</li>
                                <li>✅ Restore on any provider</li>
                                <li>✅ Be Data-Security Compliant</li>
                              </ul>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        className={`${
                          dataT != "git" ? "hidden" : "mt-4"
                        } bg-white shadow sm:rounded-lg max-w-xl`}
                      >
                        <div className="px-4 py-5 sm:p-6">
                          <h3 className="text-base font-semibold leading-6 text-gray-900">
                            Why you should backup your "GIT Data"
                          </h3>
                          <div className="mt-2 text-sm text-gray-500">
                            <p>
                              Despite GIT provider's (GitHub, GitLab, BitBucket,
                              Gitea...) reliability, there are several reasons
                              why maintaining your own backups is essential:
                              <ul className="ml-4 mt-2 flex flex-col gap-1">
                                <li>
                                  ✅ <strong>Accidental Deletion:</strong> Human
                                  error can lead to accidental deletions of
                                  repositories or branches.
                                </li>
                                <li>
                                  ✅ <strong>Repository Corruption:</strong>{" "}
                                  Though rare, data corruption can occur.
                                </li>
                                <li>
                                  ✅ <strong>Service Downtime:</strong> GitHub
                                  could experience outages that temporarily
                                  limit access to your code.
                                </li>
                                <li>
                                  ✅{" "}
                                  <strong>Compliance and Auditing ❗️:</strong>{" "}
                                  Certain industries and projects require
                                  regular backups for compliance purposes.
                                </li>
                              </ul>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        className={`${
                          dataT != "storage" ? "hidden" : "mt-4"
                        } bg-white shadow sm:rounded-lg max-w-xl`}
                      >
                        <div className="px-4 py-5 sm:p-6">
                          <h3 className="text-base font-semibold leading-6 text-gray-900">
                            Why you should backup your "Storage" (S3, Object
                            Storage...)
                          </h3>
                          <div className="mt-2 text-sm text-gray-500">
                            <p>
                              What if your cloud provider has an outage? What if
                              your account is compromised? Well, the best thing
                              you can have in this situations is another Storage
                              that already contains the replicated information
                              form your main storage.
                              <br />
                              It's then really easy to update your application
                              to point to that secondary storage and avoid any
                              downtime.
                            </p>
                            <ul className="mt-4 ml-4 gap-1 flex flex-col">
                              <li>✅ Plug-and-play providers approach</li>
                              <li>✅ Data Migration</li>
                              <li>✅ Be Data-Security Compliant</li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div
                        className={`${
                          dataT != "saas-application" ? "hidden" : "mt-4"
                        } bg-white shadow sm:rounded-lg max-w-xl`}
                      >
                        <div className="px-4 py-5 sm:p-6">
                          <h3 className="text-base font-semibold leading-6 text-gray-900">
                            Why you should backup your "SaaS Applications"
                            (Notion, Knack, ..)
                          </h3>
                          <div className="mt-2 text-sm text-gray-500">
                            <p>
                              Let's address the elephant in the room: SaaS
                              applications are reliable, secure, and the
                              providers have a strong track record of uptime.
                              <br />
                              <br />
                              Still, when it comes to backup, their policies is
                              systematically around dealing with outage they may
                              face, not the data you may lose.
                              <br />
                              Ransomware, accidental deletion, account
                              compromise, are all too common and if your
                              business relies on these applications, you need to
                              have a plan B.
                              <br />
                              <br />
                              And losing data in a SaaS application is a real
                              thing, and it's not a matter of "if" but "when".
                              Accounts get compromised, a credit card expires
                              and an account is suspended (...).
                              <br />
                              <br />
                              It's crucial to own your data, not matter what
                              happens.
                              <ul className="mt-4 ml-4 gap-1 flex flex-col">
                                <li>✅ Own your data</li>
                                <li>
                                  ✅ Data pipeline (transfer SaaS data to other
                                  systems)
                                </li>
                                <li>✅ Be Data-Security Compliant</li>
                              </ul>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </dd>
                </div>

                <div>
                  <dt className="text-lg font-bold text-warm-gray-900" id="how-is-your-data-secured">
                    Assess how your data is secured, today
                  </dt>
                  <dd className="mt-2 text-base text-warm-gray-500">
                    <p className="">
                      While a backup solution, helps you configuring your
                      backups, making sure these backups are reliable, resilient
                      and that you know how to restore them (...), all of this
                      can also be achieved without the help of a solution.
                      <br />
                      This means that no matter the option you pick, you'll have
                      to make sure you have a proper{" "}
                      <strong>Disaster Recovery Plan</strong> in place.
                    </p>
                    <p className="my-4">
                      <span className="link-highlight">
                        <strong>Backup Strategy:</strong>
                      </span>
                    </p>
                    <ul className="mt-4 list-disc ml-6">
                      <li>List all your critical assets</li>
                      <li>
                        For each, define the frequency these data have to be
                        backed up
                      </li>
                      <li>
                        Define where these backups have to be stored
                        (Multi-Storage, 3-2-1 strategy ...)
                      </li>
                      <li>Automated these backups schedules</li>
                    </ul>

                    <p className="my-4">
                      <span className="link-highlight">
                        <strong>Backup Reliability:</strong>
                      </span>
                    </p>
                    <ul className="mt-4 list-disc ml-6">
                      <li>Can you confirm your backups are well running?</li>
                      <li>
                        How do you mitigate the risk of backup
                        corruption/anomaly?
                      </li>
                      <li>
                        Can the backup strategy be easily controller by other
                        people from the team?
                      </li>
                    </ul>

                    <p className="my-4">
                      <span className="link-highlight">
                        <strong>Recovery Strategy:</strong>
                      </span>
                    </p>
                    <ul className="mt-4 list-disc ml-6">
                      <li>Define your RTO (Recovery Time Objective)</li>
                      <li>
                        Define the list of who should be able to restore your
                        backups
                      </li>
                      <li>Document the Backup Recovery process</li>
                      <li>Test your Backup Recovery frequently</li>
                    </ul>

                    <p className="mt-4">
                      ✅ If you can confirm all the above are in place, you're
                      good to go, if not, you may want to consider a solution
                      that will help you with all of this.
                    </p>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          <div className="mt-24">
            <div className="my-4 uppercase text-xs text-gray-400 tracking-wide">
              Learn more
            </div>
            <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
              <a
                className="bg-white rounded-lg p-6"
                href="/blog/please-backup-your-production-data/"
              >
                <p className="font-bold">
                  <i className="fa fa-angle-right text-2x text-primary mr-2"></i>
                  Backup your production data before it's too late.
                </p>
              </a>
              <a
                className="bg-white rounded-lg p-6"
                href="/blog/enhancing-cybersecurity-with-robust-backup-and-disaster-recovery-solutions/"
              >
                <p className="font-bold">
                  <i className="fa fa-angle-right text-2x text-primary mr-2"></i>
                  Enhancing Cybersecurity with Robust Backup
                </p>
              </a>
              <a
                className="bg-white rounded-lg p-6"
                href="/blog/is-your-data-safe-the-unseen-risk-of-not-using-backup-automation/"
              >
                <p className="font-bold">
                  <i className="fa fa-angle-right text-2x text-primary mr-2"></i>
                  Is Your Data Safe? The Unseen Risk of Not Using Backup
                  Automation
                </p>
              </a>
              <a
                className="bg-white rounded-lg p-6"
                href="/blog/backup-as-a-service-how-it-works-benefits-and-challenges/"
              >
                <p className="font-bold">
                  <i className="fa fa-angle-right text-2x text-primary mr-2"></i>
                  Backup as a Service: How It Works, Benefits, and Challenges
                </p>
              </a>
            </div>
          </div>
        </Ui.Row>
      </Ui.Container>
    </Ui.Section>
  );
}

function RealWorldUseCases(context) {
  return (
    <Ui.Section>
      <Ui.Container>
        <Ui.Row>
          <div className="lg:grid lg:grid-cols-3 lg:gap-8">
            <div>
              <h2 className="text-3xl font-extrabold " id="real-world-use-cases">Real-world use cases</h2>
              <p className="mt-4 text-lg text-warm-gray-500">
                <div className="uppercase text-xs text-gray-400 tracking-wide" id="simplebackups-walkthrough">
                  SimpleBackups walkthrough
                </div>
                <div className="text-sm mt-4">
                  <span className="bg-gray-50 rounded-lg px-3 py-2 ">
                    <span className="text-xs text-gray-400 uppercase">
                      In the lens of:{" "}
                    </span>
                    <span className="text-primary font-bold text-xs ml-2">
                      {convincingContexts[context]}
                    </span>
                  </span>
                </div>
              </p>
            </div>

            <div className="mt-12 lg:mt-0 lg:col-span-2">
              <dl className="space-y-12">
                {(context == "tech-founder" || context == "saas-app") && (
                  <div>
                    <dt className="text-lg font-bold" id="saas-tech-team">
                      SaaS & Tech team securing their products with
                      SimpleBackups
                    </dt>
                    <dd className="mt-2 text-base text-warm-gray-500">
                      SaaS companies and tech companies dealing with internal
                      development of apps, uses SimpleBackups for:
                      <ul className="mt-4 ml-6 leading-relaxed">
                        <li>
                          ✅{" "}
                          <span className="link-highlight">
                            Save time and avoid maintenance
                          </span>{" "}
                          for your development team to focus on your product
                        </li>
                        <li>
                          ✅ <span className="link-highlight">Trust</span> your
                          backups are correctly handled while you scale your
                          product
                        </li>
                        <li>
                          ✅ Clear, accessible backups for the{" "}
                          <span className="link-highlight">entire team</span>{" "}
                          (not handled by one in-house person)
                        </li>
                        <li>
                          ✅ Clear,{" "}
                          <span className="link-highlight">
                            restore process
                          </span>
                        </li>
                        <li>
                          ✅{" "}
                          <span className="link-highlight">
                            Save time on compliance efforts
                          </span>{" "}
                          (ISO 27001, SOC2, HIPAA, GDPR...)
                        </li>
                      </ul>
                      <div className="bg-gray-50 rounded-xl p-8 pb-0 flex flex-col justify-between mt-4">
                        <Ui.Image filename="simplebackups-app-flexible-storage-schedule.png"></Ui.Image>
                      </div>
                      <div className="grid md:grid-cols-3 md:gap-4 gap-2 mt-4 text-left text-base font-medium ">
                        <div className="flex items-center bg-gray-100 rounded-xl p-6">
                          <i className="text-green fa fa-check text-xl mr-3"></i>
                          <h3 className="block">No Maintenance</h3>
                        </div>
                        <div className="flex items-center bg-gray-100 rounded-xl p-6">
                          <i className="text-green fa fa-check text-xl mr-3"></i>
                          <h3 className="block">Expert support</h3>
                        </div>
                        <div className="flex items-center bg-gray-100 rounded-xl p-6">
                          <i className="text-green fa fa-check text-xl mr-3"></i>
                          <h3 className="block">ISO 27001 certified vendor</h3>
                        </div>
                      </div>
                    </dd>
                  </div>
                )}
                {context == "agency-owner" && (
                  <div>
                    <dt className="text-lg font-bold" id="agency_backup">
                      Agencies using SimpleBackups to back up their client's
                      projects
                    </dt>
                    <dd className="mt-2 text-base text-warm-gray-500">
                      Many Agencies whether they're digital agencies,
                      development studios, or marketing agencies, uses
                      SimpleBackups for:
                      <ul className="mt-4 ml-6 leading-relaxed">
                        <li>
                          ✅{" "}
                          <span className="link-highlight">
                            Save time setting up all your projects backups
                            without dev work needed
                          </span>
                        </li>
                        <li>
                          ✅{" "}
                          <span className="link-highlight">No maintenance</span>
                        </li>
                        <li>
                          ✅ All{" "}
                          <span className="link-highlight">
                            backups managed under one same roof
                          </span>
                        </li>
                        <li>
                          ✅ A sharable dashboard to show{" "}
                          <span className="link-highlight">
                            trust and professionalism towards your customers
                          </span>
                        </li>
                        <li>
                          ✅ Grow your projects base while{" "}
                          <span className="link-highlight">
                            maintaining data-security
                          </span>{" "}
                          even in a high turnover industry
                        </li>
                      </ul>
                      <div className="bg-gray-50 rounded-xl p-8 pb-0 flex flex-col justify-between mt-4">
                        <Ui.Image
                          className=""
                          filename="simplebackups-app-projects-and-sharing.png"
                        ></Ui.Image>
                      </div>
                      <div className="grid md:grid-cols-3 md:gap-4 gap-2 mt-4 text-left text-base font-medium ">
                        <div className="flex items-center bg-gray-100 rounded-xl p-6">
                          <i className="text-green fa fa-check text-xl mr-3"></i>
                          <h3 className="block">Multi projects backups</h3>
                        </div>
                        <div className="flex items-center bg-gray-100 rounded-xl p-6">
                          <i className="text-green fa fa-check text-xl mr-3"></i>
                          <h3 className="block">Compliance Control Board</h3>
                        </div>
                        <div className="flex items-center bg-gray-100 rounded-xl p-6">
                          <i className="text-green fa fa-check text-xl mr-3"></i>
                          <h3 className="block">Team Access</h3>
                        </div>
                      </div>
                    </dd>
                  </div>
                )}
                <div>
                  <dt className="text-lg font-bold" id="geek">
                    All Geeks, securing their hobby project
                  </dt>
                  <dd className="mt-2 text-base text-warm-gray-500">
                    We've always supported the idea of having a backup for your
                    hobby projects for free, and we've seen many users using
                    SimpleBackups for:
                    <ul className="mt-4 ml-6 leading-relaxed">
                      <li>
                        ✅ Easy database backup until you're ready for
                        production!
                      </li>
                      <li>✅ Infrastructure agnostic solution</li>
                    </ul>
                    <div className="bg-gray-50 rounded-xl p-8 pb-0 flex flex-col justify-between mt-4">
                      <Ui.Image
                        className=""
                        filename="simplebackups-app-flexible-storage-schedule.png"
                      ></Ui.Image>
                    </div>
                    <div className="grid md:grid-cols-3 md:gap-4 gap-2 mt-4 text-left text-base font-medium ">
                      <div className="flex items-center bg-gray-100 rounded-xl p-6">
                        <i className="text-green fa fa-check text-xl mr-3"></i>
                        <h3 className="block">No Hassle setup</h3>
                      </div>
                      <div className="flex items-center bg-gray-100 rounded-xl p-6">
                        <i className="text-green fa fa-check text-xl mr-3"></i>
                        <h3 className="block">Multi-Storage</h3>
                      </div>
                      <div className="flex items-center bg-gray-100 rounded-xl p-6">
                        <i className="text-green fa fa-check text-xl mr-3"></i>
                        <h3 className="block">Alerts and Notifications</h3>
                      </div>
                    </div>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </Ui.Row>
      </Ui.Container>
    </Ui.Section>
  );
}

function SimpleBackupsWalkthrough() {
  const dataType = {
    "managed-database": {
      imageUrl: "simplebackups-app-mysql-create.png",
      connectionLabel: "Connect your managed database",
    },
    database: {
      imageUrl: "simplebackups-app-database-selfhosted-create.png",
      connectionLabel: "Connect your database",
    },
    storage: {
      imageUrl: "simplebackups-app-storage-sync-create.png",
      connectionLabel: "Connect your storages",
    },
    "saas-application": {
      imageUrl: "simplebackups-app-saas-create.png",
      connectionLabel: "Connect your app",
    },
  };

  const [dataT, setDataT] = useState("managed-database");

  return (
    <Ui.Section color="alt">
      <Ui.Container>
        <Ui.Row>
          <div className="lg:grid lg:grid-cols-3 lg:gap-8">
            <div>
              <h2 className="text-3xl font-extrabold ">
                Discover SimpleBackups
              </h2>
              <p className="mt-4 text-lg text-warm-gray-500">
                <div className="uppercase text-xs text-gray-400 tracking-wide">
                  SimpleBackups walkthrough
                </div>
              </p>
            </div>

            <div className="mt-12 lg:mt-0 lg:col-span-2">
              <dl className="space-y-12">
                <div>
                  <p className="mb-4 text-base">
                    A quick glance at how configuring your backups within
                    SimpleBackups will look like.
                    <div className="rounded-lg mt-4 max-w-full">
                      <iframe
                        className="rounded-md w-full"
                        style={{ aspectRatio: "16 / 9" }}
                        src="https://www.youtube.com/embed/fRoaJ6XbJv0?si=l4rZYDxTu63MfFdp"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                      ></iframe>
                    </div>
                    <br />
                    Discover how SimpleBackups helps you with your:
                    <select
                      value={dataT}
                      onChange={(e) => setDataT(e.target.value)}
                      className="mt-2 ml-2 rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    >
                      <option value={"database"}>Database (self-hosted)</option>
                      <option value={"managed-database"}>
                        Managed Database
                      </option>
                      <option value={"storage"}>Storage (S3...)</option>
                      <option value={"saas-application"}>
                        SaaS Application (Git, Knack, ...)
                      </option>
                    </select>
                  </p>

                  <dt className="text-lg font-bold" id="configuring-backup">
                    <span className="link-highlight">Configuring a backup</span>
                  </dt>
                  <dd className="mt-2 text-base text-warm-gray-500">
                    <div className="mt-12 lg:mt-0 lg:col-span-2">
                      <dl className="space-y-12">
                        <div className="grid md:grid-cols-12 md:gap-4 gap-2 text-left ">
                          <div className="md:col-span-12 bg-white rounded-xl p-8 pb-0 flex flex-col justify-between">
                            <div>
                              <i className="fad fa-solid fa-circle-1 text-primary text-3xl mb-2" />
                              <h3 className="text-xl font-bold mb-1 h-8 block">
                                {dataType[dataT]["connectionLabel"]}
                              </h3>
                              <p className="text-base">
                                Configure and control all your backups from a
                                modern UI.
                                <br />
                                Connect storage, configure notifications,
                                generate audit reports, configure scheduling and
                                more right from your team space.
                              </p>
                            </div>
                            <Ui.Image
                              className="mt-4"
                              filename={dataType[dataT]["imageUrl"]}
                            ></Ui.Image>
                          </div>

                          <div className="md:col-span-6 bg-white rounded-xl p-8 pb-0 flex flex-col justify-between">
                            <div>
                              <i className="fad fa-solid fa-circle-2 text-primary text-3xl mb-2" />
                              <h3 className="text-xl font-bold mb-1 h-8 block">
                                Schedule your Backup
                              </h3>
                              <p className="text-base">
                                Configure and control all your backups from a
                                modern UI.
                                <br />
                                Connect storage, configure notifications,
                                generate audit reports, configure scheduling and
                                more right from your team space.
                              </p>
                            </div>
                            <Ui.Image
                              className="mt-4"
                              filename="simplebackups-app-schedule.png"
                            ></Ui.Image>
                          </div>

                          <div className="md:col-span-6 bg-white rounded-xl p-8 pb-0 flex flex-col justify-between">
                            <div>
                              <i className="fad fa-solid fa-circle-3 text-primary text-3xl mb-2" />
                              <h3 className="text-xl font-bold mb-1 h-8 block">
                                Define where to store your Backup
                              </h3>
                              <p className="text-base">
                                Configure and control all your backups from a
                                modern UI.
                                <br />
                                Connect storage, configure notifications,
                                generate audit reports, configure scheduling and
                                more right from your team space.
                              </p>
                            </div>
                            <Ui.Image
                              className="mt-4"
                              filename="simplebackups-app-storage.png"
                            ></Ui.Image>
                          </div>
                        </div>
                      </dl>
                    </div>
                  </dd>
                </div>
                <div>
                  <dt className="text-lg font-bold" id="restoring-backup">
                    <span className="link-highlight">Restoring a backup</span>
                  </dt>
                  <dd className="mt-2 text-base text-warm-gray-500">
                    <div className="mt-12 lg:mt-0 lg:col-span-2">
                      <dl className="space-y-12">
                        <div className="grid md:grid-cols-12 md:gap-4 gap-2 text-left ">
                          <div className="md:col-span-12 bg-white rounded-xl p-8 pb-0 flex flex-col justify-between">
                            <div>
                              <i className="fad fa-solid fa-circle-1 text-primary text-3xl mb-2" />
                              <h3 className="text-xl font-bold mb-1 h-8 block">
                                Select the Backup you want to restore
                              </h3>
                              <p className="text-base">
                                Configure and control all your backups from a
                                modern UI.
                                <br />
                                Connect storage, configure notifications,
                                generate audit reports, configure scheduling and
                                more right from your team space.
                              </p>
                            </div>
                            <Ui.Image
                              className="mt-4"
                              filename="simplebackups-app-mysql-loginfo.png"
                            ></Ui.Image>
                          </div>

                          <div className="md:col-span-6 bg-white rounded-xl p-8 pb-0 flex flex-col justify-between">
                            <div>
                              <i className="fad fa-solid fa-circle-2 text-primary text-3xl mb-2" />
                              <h3 className="text-xl font-bold mb-1 h-8 block">
                                Select your a restore method
                              </h3>
                              <p className="text-base">
                                Configure and control all your backups from a
                                modern UI.
                                <br />
                                Connect storage, configure notifications,
                                generate audit reports, configure scheduling and
                                more right from your team space.
                              </p>
                            </div>
                            <Ui.Image
                              className="mt-4"
                              filename="simplebackups-app-restore-methods.png"
                            ></Ui.Image>
                          </div>

                          <div className="md:col-span-6 bg-white rounded-xl p-8 pb-0 flex flex-col justify-between">
                            <div>
                              <i className="fad fa-solid fa-circle-3 text-primary text-3xl mb-2" />
                              <h3 className="text-xl font-bold mb-1 h-8 block">
                                Execute the restore command
                              </h3>
                              <p className="text-base">
                                Configure and control all your backups from a
                                modern UI.
                                <br />
                                Connect storage, configure notifications,
                                generate audit reports, configure scheduling and
                                more right from your team space.
                              </p>
                            </div>
                            <Ui.Image
                              className="mt-4"
                              filename="simplebackups-app-restore-button.png"
                            ></Ui.Image>
                          </div>
                        </div>
                      </dl>
                    </div>
                  </dd>
                </div>
                <div>
                  <dt className="text-lg font-bold">
                    <span className="link-highlight">
                      Backup Recovery Plan, Compliance Dashboard and Security
                      Options
                    </span>
                  </dt>
                  <dd className="mt-2 text-base text-warm-gray-500">
                    <div className="mt-12 lg:mt-0 lg:col-span-2">
                      <dl className="space-y-12">
                        <div className="grid md:grid-cols-12 md:gap-4 gap-2 text-left ">
                          <div className="md:col-span-12 bg-white rounded-xl p-8 pb-0 flex flex-col justify-between">
                            <div>
                              <i className="fad fa-solid fa-circle-1 text-primary text-3xl mb-2" />
                              <h3 className="text-xl font-bold mb-1 h-8 block">
                                Share your Backup Dashboard to your team/clients
                              </h3>
                              <p className="text-base">
                                Configure and control all your backups from a
                                modern UI.
                                <br />
                                Connect storage, configure notifications,
                                generate audit reports, configure scheduling and
                                more right from your team space.
                              </p>
                            </div>
                            <Ui.Image
                              className="mt-4"
                              filename="simplebackups-app-projects-and-sharing.png"
                            ></Ui.Image>
                          </div>

                          <div className="md:col-span-6 bg-white rounded-xl p-8 pb-0 flex flex-col justify-between">
                            <div>
                              <i className="fad fa-solid fa-circle-2 text-primary text-3xl mb-2" />
                              <h3 className="text-xl font-bold mb-1 h-8 block">
                                Export Audit Reports
                              </h3>
                              <p className="text-base">
                                Configure and control all your backups from a
                                modern UI.
                                <br />
                                Connect storage, configure notifications,
                                generate audit reports, configure scheduling and
                                more right from your team space.
                              </p>
                            </div>
                            <Ui.Image
                              className="mt-4"
                              filename="simplebackups-app-github-compliance.png"
                            ></Ui.Image>
                          </div>

                          <div className="md:col-span-6 bg-white rounded-xl p-8 pb-0 flex flex-col justify-between">
                            <div>
                              <i className="fad fa-solid fa-circle-3 text-primary text-3xl mb-2" />
                              <h3 className="text-xl font-bold mb-1 h-8 block">
                                Configure Reporting
                              </h3>
                              <p className="text-base">
                                Configure and control all your backups from a
                                modern UI.
                                <br />
                                Connect storage, configure notifications,
                                generate audit reports, configure scheduling and
                                more right from your team space.
                              </p>
                            </div>
                            <Ui.Image
                              className="mt-4"
                              filename="simplebackups-app-config-reporting.png"
                            ></Ui.Image>
                          </div>

                          <div className="md:col-span-6 bg-white rounded-xl p-8 pb-0 flex flex-col justify-between">
                            <div>
                              <i className="fad fa-solid fa-circle-4 text-primary text-3xl mb-2" />
                              <h3 className="text-xl font-bold mb-1 h-8 block">
                                Enable Backup Testings
                              </h3>
                              <p className="text-base">
                                Configure and control all your backups from a
                                modern UI.
                                <br />
                                Connect storage, configure notifications,
                                generate audit reports, configure scheduling and
                                more right from your team space.
                              </p>
                            </div>
                            <Ui.Image
                              className="mt-4"
                              filename="simplebackups-app-recovery-reminder.png"
                            ></Ui.Image>
                          </div>

                          <div className="md:col-span-6 bg-white rounded-xl p-8 pb-0 flex flex-col justify-between">
                            <div>
                              <i className="fad fa-solid fa-circle-5 text-primary text-3xl mb-2" />
                              <h3 className="text-xl font-bold mb-1 h-8 block">
                                Setup end-to-end Encryption
                              </h3>
                              <p className="text-base">
                                Configure and control all your backups from a
                                modern UI.
                                <br />
                                Connect storage, configure notifications,
                                generate audit reports, configure scheduling and
                                more right from your team space.
                              </p>
                            </div>
                            <Ui.Image
                              className="mt-4"
                              filename="simplebackups-app-backup-encryption.png"
                            ></Ui.Image>
                          </div>
                        </div>
                      </dl>
                    </div>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </Ui.Row>
      </Ui.Container>
    </Ui.Section>
  );
}

function DataSecurityWithSimpleBackups() {
  return (
    <Ui.Section>
      <Ui.Container>
        <Ui.Row>
          <div className="lg:grid lg:grid-cols-3 lg:gap-8">
            <div>
              <h2 className="text-3xl font-extrabold ">
                Data Security with SimpleBackups
              </h2>
              <p className="mt-4 text-lg text-warm-gray-500">
                <div className="uppercase text-xs text-gray-400 tracking-wide">
                  Data Security, Compliance at SimpleBackups
                </div>
              </p>
            </div>

            <div className="mt-12 lg:mt-0 lg:col-span-2">
              <dl className="space-y-12">
                <div className="grid md:grid-cols-4 md:gap-4 gap-2 text-left text-base font-medium ">
                  <div className="flex items-center bg-gray-100 rounded-xl p-6">
                    <h3 className="block">Based in Europe</h3>
                  </div>
                  <div className="flex items-center bg-gray-100 rounded-xl p-6">
                    <h3 className="block">ISO 27001 Certified</h3>
                  </div>
                  <div className="flex items-center bg-gray-100 rounded-xl p-6">
                    <h3 className="block">SOC2 Compliant</h3>
                  </div>
                  <div className="flex items-center bg-gray-100 rounded-xl p-6">
                    <h3 className="block">HIPAA Compliant</h3>
                  </div>
                  <div className="flex items-center bg-gray-100 rounded-xl p-6">
                    <h3 className="block">GDPR Compliant</h3>
                  </div>
                  <div className="flex items-center bg-gray-100 rounded-xl p-6">
                    <h3 className="block">Auditing & Security Programs</h3>
                  </div>
                </div>
                <div>
                  <dt className="text-lg font-bold" id="encryption-transit-stored">
                    Encryption, in transit, at rest
                  </dt>
                  <dd className="mt-2 text-base text-warm-gray-500">
                    At SimpleBackups we're focusing on providing the most secure
                    and transparent backup solution, and that's why we've built
                    a{" "}
                    <a className="text-primary underline" href="">
                      "security-first" page
                    </a>{" "}
                    that documents every data we deal with and how we deal with
                    it.

                    <div className="max-w-xl my-12 m-auto">
                      <Ui.Image filename="simplebackups-db-backup-flow.png" alt="simple backups database backup flowchart" />
                    </div>
                    <p className="mt-4">
                      <p>
                        <span className="link-highlight">
                          <strong>Your Backup configurations</strong>
                        </span>
                      </p>
                      Depending on backup types, SimpleBackups will require
                      you to input different kinds of credentials in order to be
                      able to run your backup.
                      <br />
                      <br />
                      <strong>
                        All of these data are systematically encrypted and
                        securely stored using a rotating key.
                      </strong>
                      <br />
                      <br />
                      On top of that, you don't have to provide root access to
                      SimpleBackups, in face we recommend against it. Root access is always something to avoid,
                      and we've built our solution to be able to backup your
                      data without it and by requiring the least possible permissions from you.
                      <br />
                      <br />
                      ℹ️ Some backup types also allow you to get credentials
                      configured on your end without storng them on SimpleBackups
                    </p>
                    <p className="mt-4">
                      <p>
                        <span className="link-highlight">
                          <strong>Backup data access</strong>
                        </span>
                      </p>
                      During the backup process NO DATA is transiting via
                      SimpleBackups infrastructure. Meaning that your data is
                      directly sent to the storage you've configured, without
                      any intermediary.
                      <br />
                      <br />
                      The only exception to this is when using Serverless, where
                      the backup is streamed from SimpleBackups isolated serverless
                      infrastucture to your storage. Even in that case the data
                      is never stored on our end.
                    </p>
                    <p className="mt-4">
                      <p>
                        <span className="link-highlight">
                          <strong>Encryption in transit</strong>
                        </span>
                      </p>
                      We use encryption at rest and in transit for all your
                      backups. This means that you can configure your backups to be
                      encrypted using your own private key but also that all the
                      data transiting between SimpleBackups and your storage is
                      encrypted using AES256.
                      <br />
                      <br />
                      As mentioned above the data stored on SimpleBackups
                      (configuration...) are encrypted using a rotating key. Adding your own encryption key to backups means that no backups are readable by anyone, even
                      us at transit, since the encrypted output is sent to your storage. The process happens in complete autonomy with real-time logs available for you to check.
                    </p>
                    <p className="mt-4">
                      <p>
                        <span className="link-highlight">
                          <strong>Encryption on storage</strong>
                        </span>
                      </p>
                      Your backup can be stored on any connected Storage
                      Provider and you can define an encryption key generated on
                      your end, unique to you, that will be used to encrypt your
                      backup archive before the transit.
                      <br />
                      This means only you, owner of your private key, can ever
                      decrypt it (as long as you don't lose the key of course).
                      <br/>
                      Additionally, SimpleBackups supports technologies like SSE-C to ensure your data is encrypted on the storage provider side.
                    </p>
                  </dd>
                </div>
                <div>
                  <dt className="text-lg font-bold" id="compliance-auditing">
                    Certifications, compliance and auditing
                  </dt>
                  <dd className="mt-2 text-base text-warm-gray-500">
                    While many self-claim to be ISO 27001 "compliant" fewer are
                    actually "certified".
                    <br />
                    We, at SimpleBackups, are certified since 2023, and are being
                    audited on a yearly-basis.
                    <br />
                    <br />
                    <span className="link-highlight">
                      Being ISO 27001 certified means we have obligations
                    </span>
                    , when it comes to implementation of strict data security
                    measures and that everything we do is documented and
                    audited.
                    <br />
                    <br />
                    <span className="link-highlight">
                      We're also based in Europe, where data security is known to be heavily scrutinized, and we're GDPR compliant
                    </span>
                    , meaning that we have to follow strict data security regulations
                    and that we have to provide you with all the tools you need
                    to be compliant as well.
                    <br />
                    <br />
                    While we're not officially SOC2 and HIPAA certified, we are
                    compliant with their requirements and can provide you with
                    all the documentation you may need to attest of our
                    compliance.
                    <p className="mt-4">
                      <p>
                        <span className="link-highlight">
                          <strong>ISO 27001 Requirements</strong>
                        </span>
                      </p>

                      <div className="mt-4 border-l-4 border-gray-100 pl-8 max-w-2xl text-justify">
                        ISO 27001, specifically in Annex A.12.3, outlines
                        requirements for "backup" and disaster recovery in the
                        context of information security. It mandates that
                        organizations implement appropriate backup procedures to
                        protect against data loss, ensuring that information and
                        software essential to business operations are regularly
                        backed up. Backups must be tested periodically to verify
                        their integrity and effectiveness in disaster recovery
                        scenarios. The standard requires that backup copies are
                        stored securely, with defined access controls, and that
                        they are kept separate from the operational environment
                        to prevent data corruption or loss in case of system
                        failure. Additionally, the organization must ensure that
                        backup policies align with business continuity and
                        disaster recovery plans, and that backup procedures
                        comply with legal, regulatory, and contractual
                        obligations.
                      </div>
                    </p>
                    <p className="mt-4">
                      <p>
                        <span className="link-highlight">
                          <strong>GDPR Requirements</strong>
                        </span>
                      </p>
                      <div className="mt-4 border-l-4 border-gray-100 pl-8 max-w-2xl text-justify">
                        The GDPR does not explicitly mandate backups, but it
                        requires organizations to implement appropriate
                        technical and organizational measures to ensure the
                        security, availability, and resilience of personal data,
                        as stated in Article 32. While backups are not a direct
                        requirement, they are often necessary to meet these
                        obligations, particularly for ensuring data availability
                        and timely recovery in the event of system failures,
                        breaches, or other disruptions. Consequently, having a
                        robust backup strategy is typically part of
                        demonstrating compliance. Moreover, any backups
                        containing personal data must adhere to GDPR principles,
                        such as data confidentiality and integrity, which means
                        they must be protected with measures like encryption and
                        access controls.
                      </div>
                    </p>
                    <p className="mt-4">
                      <p>
                        <span className="link-highlight">
                          <strong>SOC2 Requirements</strong>
                        </span>
                      </p>
                      <div className="mt-4 border-l-4 border-gray-100 pl-8 max-w-2xl text-justify">
                        In the context of SOC2, backups are not explicitly
                        required, but they are commonly implemented to meet the{" "}
                        <strong>Availability and Security principles</strong>.
                        <br />
                        SOC2 focuses on ensuring that systems are reliable and
                        available as committed, and backups are a typical way to
                        achieve this. While organizations have flexibility in
                        how they meet these criteria, a strong backup and
                        disaster recovery plan helps demonstrate the ability to
                        restore data and maintain operations during disruptions.
                        Backups also need to be secured with proper access
                        controls and encryption to comply with SOC2’s
                        confidentiality and security standards.
                      </div>
                    </p>
                    <p className="mt-4">
                      <p>
                        <span className="link-highlight">
                          <strong>HIPAA Requirements</strong>
                        </span>
                      </p>
                      <div className="mt-4 border-l-4 border-gray-100 pl-8 max-w-2xl text-justify">
                        Under HIPAA’s <strong>Security Rule</strong>, backups
                        are explicitly required to ensure the availability and
                        integrity of Protected Health Information (PHI).
                        <br />
                        The <strong>Contingency Plan</strong> standard (45 CFR §
                        164.308(a)(7)(ii)(A)) mandates that covered entities and
                        business associates implement a data{" "}
                        <strong>backup plan</strong> to create and maintain
                        retrievable copies of electronic PHI. This ensures that
                        critical health data can be restored in case of
                        emergencies or data loss. Backups must also be protected
                        with appropriate security measures, such as encryption
                        and access controls, to meet HIPAA’s confidentiality,
                        integrity, and availability requirements. Regular
                        testing and verification of these backup plans are
                        strongly recommended to ensure effectiveness.
                      </div>
                    </p>
                  </dd>
                </div>
                <div>
                  <dt className="text-lg font-bold">
                    Code of conduct, and security practices
                  </dt>
                  <dd className="mt-2 text-base text-warm-gray-500">
                    <p className="mt-4">
                      <p>
                        <span className="link-highlight">
                          <strong>Minimal access to the team</strong>
                        </span>
                      </p>
                      As described in our
                      <a className="text-primary underline" href="">
                        "security-first" page
                      </a>{" "}
                      the data stored on SimpleBackups (configuration...) are encrypted with no one in the team having access to your raw data, at any time.
                      <br />
                      <br />
                      On top of encryption mechanisms, you're also able to
                      encrypt your backups using your own key, this
                      ensure the data stored on your end is also encrypted.
                    </p>

                    <p className="mt-4">
                      <p>
                        <span className="link-highlight">
                          <strong>ISO 27001 documentation trail</strong>
                        </span>
                      </p>
                      We keep track of all that touches security, and log it.
                      <br />
                      This is a requirements for us to be ISO 27001 certified. We also, keep an opened{" "}
                      <a
                        href="https://simplebackups.instatus.com/"
                        target="_blank"
                      >
                        "Status Page"
                      </a>{" "}
                      that will list any system failure our outage, informing
                      you in real time of what happens but also showing you that
                      we're transparent about our system.
                    </p>

                    {/*<p className="mt-4">*/}
                    {/*  <p>*/}
                    {/*    <span className="link-highlight">*/}
                    {/*      <strong>Activity log</strong>*/}
                    {/*    </span>*/}
                    {/*  </p>*/}
                    {/*  Every operations you do on SimpleBackups is logged.*/}
                    {/*  <br /> We can at any time track, who did what.*/}
                    {/*</p>*/}

                    <p className="mt-4">
                      <p>
                        <span className="link-highlight">
                          <strong>Bug Bounty Programs</strong>
                        </span>
                      </p>
                      {/*The best way to challenge our security measures, is to
                      constantly test them!
                      <br />*/}
                      While we have strong development practices and automated
                      test systems that ensure everything we do is secure,
                      tested, and reviewed we also leverage the skills of
                      talented security experts that are not part of our team to
                      challenge our system.
                    </p>
                  </dd>
                </div>
                <div>
                  <dt className="text-lg font-bold" id="questions-concerns">Questions and concerns</dt>
                  <dd className="mt-2 text-base text-warm-gray-500">
                    Choosing the right Backup Solution is a critical decision
                    for your business, and we understand that.
                    <br />
                    We're here to help you make the right decision, and we're
                    here to answer any questions you may have.
                    <br />
                    <br />
                    We've bundled below the most common questions users are
                    asking us:
                    <p className="mt-4">
                      <p>
                        <span className="link-highlight">
                          <strong>
                            What access does SimpleBackups need?
                          </strong>
                        </span>
                      </p>
                      Short answer - You can provide read only access limited to what needs to be backed up, and we
                      encourage you to use a dedicated user for backups.<br/><br/>
                      Long answer - We're ISO 27001 certified and follow
                      strict security guidelines, we do not store any access config without proper encryption, have
                      encryption with rotating keys, encourage firewall restrictions, ...<br/>
                      With that, we also encourage proper permission management, and giving SimpleBackups the least possible permissions required to complete each backup.
                    </p>
                    {/*<p className="mt-4">*/}
                    {/*  <p>*/}
                    {/*    <span className="link-highlight">*/}
                    {/*      <strong>Question 1</strong>*/}
                    {/*    </span>*/}
                    {/*  </p>*/}
                    {/*  Answer 1*/}
                    {/*</p>*/}
                    {/*<p className="mt-4">*/}
                    {/*  <p>*/}
                    {/*    <span className="link-highlight">*/}
                    {/*      <strong>Question 1</strong>*/}
                    {/*    </span>*/}
                    {/*  </p>*/}
                    {/*  Answer 1*/}
                    {/*</p>*/}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </Ui.Row>
      </Ui.Container>
    </Ui.Section>
  );
}

function YourObligationsWhenItComesToBackup(context, shownFor) {
  return (
    <Ui.Section color="alt">
      <Ui.Container>
        <Ui.Row>
          <div className="lg:grid lg:grid-cols-3 lg:gap-8">
            <div>
              <h2 className="text-3xl font-extrabold " id="obligations">
                Your obligations when it comes to backup
              </h2>
              <p className="mt-4 text-lg text-warm-gray-500">
                <div className="uppercase text-xs text-gray-400 tracking-wide">
                  Understanding BaaS
                </div>
                <div className="text-sm mt-4">
                  <span className="bg-white rounded-lg px-3 py-2 ">
                    <span className="text-xs text-gray-400 uppercase">
                      In the lens of:{" "}
                    </span>
                    <span className="text-primary font-bold text-xs ml-2">
                      {convincingContexts[context]}
                    </span>
                  </span>
                </div>
              </p>
            </div>

            <div className="mt-12 lg:mt-0 lg:col-span-2">
              <dl className="">
                <div className={shownFor("agency-owner")}>
                  <dt className="text-lg font-bold" id="obligations-agencies">
                    The case for Agencies and Development Shops
                  </dt>
                  <dd className="mt-2 text-base text-warm-gray-500">
                    As a service agency (digital agency, development studio,
                    etc.), your clients trust you to protect what you've built
                    for them, along with the data generated through these
                    projects.
                    <br />
                    <br />
                    Whether infrastructure and hosting are part of your offering
                    or not, you need to manage or configure the necessary tools
                    to ensure their data is secure—an industry standard.
                    <br />
                    <br />
                    By using a Backup-as-a-Service (BaaS) solution, you can
                    configure all your projects' backups from a single
                    interface, granting access to the customers who need it
                    while keeping everything private and secure. You can also
                    provide your customers with access to a simplified "Disaster
                    Recovery and Compliance Dashboard," allowing them to monitor
                    their backup activity and access all required compliance
                    reporting.
                  </dd>
                </div>

                <div className={shownFor("tech-founder")}>
                  <dt className="text-lg font-bold">
                    The case for SaaS and Tech teams
                  </dt>
                  <dd className="mt-2 text-base text-warm-gray-500">
                    In the case of SaaS companies, or tech teams dealing with
                    the development of products supporting a business, you
                    probably have some sort of backup mechanism in place.
                    <br />
                    If you don't, you should,.. period!
                    <br />
                    <br />
                    In too many case, these things are handled by the in-house
                    development team, which knows how to configure and run
                    backups of the system they've built. And that's usually
                    great, until it's really tested.
                    <br />
                    Your obligations as a tech-lead in your company is to
                    guarantee that your systems run smoothly, and that the data
                    generated via your systems is safe and secure.
                    <br />
                    <br />
                    <span className="link-highlight">
                      It's not about having a script, configured to run every
                      night, it's about ensuring that the data is safe, and that
                      your team can recover it.
                    </span>
                    <br />
                    <br />
                    Practically, it means you have to:
                    <ul className="list-disc mt-2 ml-6">
                      <li>Create backups scripts/routines</li>
                      <li>
                        Have monitoring systems in place alerting you of any
                        anomalies
                      </li>
                      <li>
                        Have the system documented, accessible by the entire
                        team
                      </li>
                      <li>Regular tests</li>
                    </ul>
                  </dd>
                </div>

                <div className="mt-12">
                  <dt className="text-lg font-bold text-warm-gray-900" id="data-security-compliance">
                    Data Security, and compliance regulations with Backups
                  </dt>
                  <dd className="mt-2 text-base text-warm-gray-500">
                    A reliable BaaS must ensure all data is secured using
                    end-to-end encryption, ensuring it remains private and
                    tamper-proof throughout the entire backup process.
                    <br />
                    <br />
                    Additionally,{" "}
                    <span className="link-highlight">
                      compliance with data protection regulations such as GDPR,
                      ISO 27001, HIPAA, SOC2, and other industry standards will
                      require you to have Disaster Recovery plan in place
                    </span>
                    .
                    <br />
                    SimpleBackups helps you stay compliant by providing secured
                    backups, audit logs, and customizable data retention
                    policies, ensuring your business meets regulatory
                    requirements without added complexity.
                    <br />
                    <br />
                    <span className="link-highlight">
                      This means you not only protect your data but also reduce
                      legal and financial risks associated with non-compliance.
                    </span>
                  </dd>
                </div>
              </dl>
            </div>
          </div>

          <div className="mt-24">
            <div className="my-4 uppercase text-xs text-gray-400 tracking-wide">
              Learn more
            </div>
            <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
              <a
                className="bg-white rounded-lg p-6"
                href="/blog/mastering-gdpr-compliance-the-essential-business-owners-handbook/"
              >
                <p className="font-bold">
                  <i className="fa fa-angle-right text-2x text-primary mr-2"></i>
                  Mastering GDPR Compliance: The Essential Business Owner's
                  Handbook
                </p>
              </a>
              <a
                className="bg-white rounded-lg p-6"
                href="/blog/security-for-saas-applications-understanding-the-benefits-examples-challenges-and-best-practices/"
              >
                <p className="font-bold">
                  <i className="fa fa-angle-right text-2x text-primary mr-2"></i>
                  Security for SaaS Applications: Understanding the Benefits,
                  Examples, Challenges, and Best Practices
                </p>
              </a>
              <a
                className="bg-white rounded-lg p-6"
                href="/blog/enhancing-cybersecurity-with-robust-backup-and-disaster-recovery-solutions/"
              >
                <p className="font-bold">
                  <i className="fa fa-angle-right text-2x text-primary mr-2"></i>
                  Enhancing Cybersecurity with Robust Backup
                </p>
              </a>
            </div>
          </div>
        </Ui.Row>
      </Ui.Container>
    </Ui.Section>
  );
}

function UnderstandWhatBaasIsAndWhyYouMightNeedIt(context) {
  return (
    <Ui.Section color="">
      <Ui.Container>
        <Ui.Row>
          <div className="lg:grid lg:grid-cols-3 lg:gap-8">
            <div>
              <h2 className="text-3xl font-extrabold " id="understand-baas">
                Understand what Backup-as-a-Service (BaaS) is and why you might need
                it?
              </h2>
              <p className="mt-4 text-lg text-warm-gray-500">
                <div className="uppercase text-xs text-gray-400 tracking-wide">
                  Understanding BaaS
                </div>
              </p>
            </div>

            <div className="mt-12 lg:mt-0 lg:col-span-2">
              <dl className="space-y-12">
                <div className="grid md:grid-cols-12 md:gap-4 gap-2 text-left ">
                  <div className="col-span-6 row-span-2 bg-gray-100 rounded-xl p-8 pb-0 flex flex-col justify-between">
                    <div>
                      <i className="fad fa-solid fa-clipboard-list-check text-primary text-4xl mb-4" />
                      <h3 className="text-xl font-bold mb-1 h-8 block">
                        Backup Management Interface
                      </h3>
                      <p className="text-base">
                        Configure and control all your backups from a modern UI.
                        <br />
                        Connect storage, configure notifications, generate audit
                        reports, configure scheduling and more right from your
                        team space.
                      </p>
                    </div>
                    <Ui.Image
                      className="mt-4"
                      filename="simplebackups-app-flexible-storage-schedule.png"
                    ></Ui.Image>
                  </div>

                  <div className="col-span-6 bg-gray-100 rounded-xl p-8">
                    <i className="fad fa-solid fa-shield-check text-primary text-4xl mb-4" />
                    <h3 className="text-xl font-bold mb-1 h-8 block">
                      Disaster Recovery
                    </h3>
                    <p className="text-base">
                      Worry-free backup recovery with clear steps and
                      automation: anyone in your team can restore your data at
                      any time.
                      <br />
                      No guessing,{" "}
                      <span className="link-highlight">
                        pick a backup and restore it in a few clicks
                      </span>
                      .
                    </p>
                  </div>

                  <div className="col-span-6 bg-gray-100 rounded-xl p-8">
                    <i className="fad fa-binary-lock text-primary text-3xl mb-4" />
                    <h3 className="text-xl font-bold mb-1 h-8 block">
                      No Maintenance
                    </h3>
                    <p className="text-base">
                      Backups is critical, know when something fails, be ready
                      for version updates without maintenance, nor code.
                    </p>
                  </div>

                  <div className="col-span-6 bg-gray-100 rounded-xl p-8">
                    <i className="fad fa-fingerprint text-primary text-4xl mb-4" />
                    <h3 className="text-xl font-bold mb-1 h-8 block">
                      Compliance & Audit ready
                    </h3>
                    <p className="text-base">
                      We got you covered with our{" "}
                      <span className="link-highlight">
                        compliance dashboard
                      </span>{" "}
                      and audit exports helping you getting GDPR, HIPAA, SOC2,
                      ISO 27001 compliant smoothly.
                      <br /> SimpleBakcups provides all the tools and
                      documentation you need to pass your audits.
                    </p>
                  </div>

                  <div className="col-span-6 bg-gray-100 rounded-xl p-8">
                    <i className="fad fa-binary-lock text-primary text-3xl mb-4" />
                    <h3 className="text-xl font-bold mb-1 h-8 block">
                      Alerts & Detections
                    </h3>
                    <p className="text-base">
                      Backups have to be monitored constantly and you need to
                      know when something goes wrong.
                      <span className="link-highlight">
                        Built-in advanced notifications, anomaly detection, and
                        reporting
                      </span>
                      .
                    </p>
                  </div>
                  <div className="col-span-6 bg-gray-100 rounded-xl p-8">
                    <i className="fad fa-binary-lock text-primary text-3xl mb-4" />
                    <h3 className="text-xl font-bold mb-1 h-8 block">
                      Security Built-in
                    </h3>
                    <p className="text-base">
                      Keeping your backups secured is an essential aspect of
                      your DR plan.
                      <span className="link-highlight">
                        Built-in end-to-end Encryption, Private keys handling,
                        SSL, MFA for all your backups
                      </span>
                      .
                    </p>
                  </div>
                  <div className="col-span-6 bg-gray-100 rounded-xl p-8">
                    <i className="fad fa-rectangle-code text-primary text-3xl mb-4" />
                    <h3 className="text-xl font-bold mb-1 h-8 block ">
                      API & Automation
                    </h3>
                    <p className="text-base">
                      Automate your backups using our{" "}
                      <span className="link-highlight">
                        API and automation end-points
                      </span>{" "}
                      to integrated backups in your development process.
                    </p>
                  </div>
                </div>

                <div>
                  <dt className="text-lg font-bold" id="alternative-backup-solutions">
                    What are the alternative backup solutions?
                  </dt>
                  <dd className="mt-2 text-base text-warm-gray-500">
                    There are 3 main alternatives to BaaS Solutions:
                    <p className="mt-4">
                      <p>
                        <span className="link-highlight">
                          <strong>1. On-premise Solutions</strong>
                        </span>
                      </p>
                      Many backups solutions often tailored for MSP, like VEEAM,
                      allow you to configure backups for most systems. It can be
                      great for MSP dealing with the infrastructure of many
                      (100+) customers and having strong in-house DevOPS team
                      that know about these solutions.
                      <br />
                      These solutions usually also integrate backups for
                      personal computers and most common company softwares
                      (MS365...). This won't be a self-served, setup in no time
                      and with clear and transparent pricing options though. It
                      will require the help of a consultant or a in-house devops
                      team to maintain it.
                      <div className="rounded-lg bg-gray-50 p-4 mt-4 text-sm max-w-xl">
                        <ul className="gap-1 flex flex-col">
                          <li>⚠️ Complicated setup</li>
                          <li>
                            ⚠️ Requires third party experts to setup and
                            maintain
                          </li>
                        </ul>
                      </div>
                    </p>
                    <p className="mt-4">
                      <p>
                        <span className="link-highlight">
                          <strong>2. Custom Solution</strong>
                        </span>
                      </p>
                      While backups may sound easy, there's an iceberg situation
                      here. For some backup types, like a basic MySQL small
                      database it may be easy to setup a shell script and
                      configure a CRON to automate it but it's not something you
                      can rely on in a production environment. If you have the
                      resources to build it in-house, you'll have to tackle
                      topics like:
                      <ul className="list-disc ml-8 mt-2">
                        <li>
                          Storing Backup on external Storages and Multi-cloud
                          resiliency
                        </li>
                        <li>Backup Rotation Mechanisms</li>
                        <li>
                          Notifications and reporting in case something goes
                          wrong
                        </li>
                        <li>Team access</li>
                        <li>Easy to access reports, for auditing</li>
                        <li>Encryption, and overall data-security</li>
                        <li>Version updates and security patches</li>
                        <li>...</li>
                      </ul>
                      <div className="rounded-lg bg-gray-50 p-4 mt-4 text-sm max-w-xl">
                        <ul className="gap-1 flex flex-col">
                          <li>⚠️ Requires maintenance</li>
                          <li>⚠️ Is rarely tested</li>
                          <li>
                            ⚠️ Lacks essential features allowing to trust
                            backups and disaster recovery plan
                          </li>
                        </ul>
                      </div>
                    </p>
                    <p className="mt-4">
                      <p>
                        <span className="link-highlight">
                          <strong>3. Cloud Provider Built-in Solution</strong>
                        </span>
                      </p>
                      You'll find most Cloud Provider offering built-in backups
                      solutions for most of their services. Most will offer
                      things like automatic daily server Snapshots and some will
                      offer more advanced services backups like managed database
                      backups. While this is a convenient solution, it's a red
                      flag for every Disaster Recovery Plan and here are a few
                      reasons why:
                      <ul className="list-disc ml-8 mt-2">
                        <li>If the IaaS has an outage, so does your backups</li>
                        <li>
                          If your IaaS account is compromised, your backups are
                          compromised as well
                        </li>
                        <li>
                          This is a single point of failure, ideal for
                          ransomware scenario
                        </li>
                        <li>IaaS never offers flexible scheduling options</li>
                        <li>
                          IaaS per definition do not offer multi-cloud solution
                        </li>
                        <li>
                          IaaS do not offer granular backups, but usually a very
                          generic backup, that will require a full system
                          restore
                        </li>
                        <li>
                          Lack of cost optimization (because backups are bound
                          to the same provider)
                        </li>
                        <li>...</li>
                      </ul>
                      <div className="rounded-lg bg-gray-50 p-4 mt-4 text-sm max-w-xl">
                        <ul className="gap-1 flex flex-col">
                          <li>
                            ⚠️ Cannot be trusted for data-safety (backups
                            centralised with data)
                          </li>
                          <li>
                            ⚠️ Lacks granularity (need to run at specific
                            time...)
                          </li>
                          <li>⚠️ Backups are not "owned" by you</li>
                        </ul>
                      </div>
                    </p>
                  </dd>
                </div>

                <div>
                  <dt className="text-lg font-bold" id="key-features">
                    What are the key features you should be looking for in a
                    BaaS solution?
                  </dt>
                  <dd className="mt-4 text-base text-warm-gray-500">
                    <div className="grid md:grid-cols-4 md:gap-4 gap-2 text-left text-base font-medium ">
                      <div className="flex bg-gray-100 rounded-xl p-6">
                        <h3 className="block">Multi-Cloud Backups</h3>
                      </div>
                      <div className="flex bg-gray-100 rounded-xl p-6">
                        <h3 className="block">Serverless Solution</h3>
                      </div>
                      <div className="flex bg-gray-100 rounded-xl p-6">
                        <h3 className="block">Encryption & Compliance</h3>
                      </div>
                      <div className="flex bg-gray-100 rounded-xl p-6">
                        <h3 className="block">Alerts & Anomaly Detections</h3>
                      </div>
                      <div className="flex bg-gray-100 rounded-xl p-6">
                        <h3 className="block">Provider Agnostic Solution</h3>
                      </div>
                      <div className="flex bg-gray-100 rounded-xl p-6">
                        <h3 className="block">Built-in Backup Restore</h3>
                      </div>
                      <div className="flex bg-gray-100 rounded-xl p-6">
                        <h3 className="block">Human Support</h3>
                      </div>
                      <div className="flex bg-gray-100 rounded-xl p-6">
                        <h3 className="block">No code, no maintenance</h3>
                      </div>
                    </div>
                  </dd>
                </div>
              </dl>
            </div>
          </div>

          <div className="mt-24">
            <div className="my-4 uppercase text-xs text-gray-400 tracking-wide">
              Learn more
            </div>
            <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
              <a
                className="bg-gray-50 rounded-lg p-6"
                href="/blog/understanding-rto-and-rpo-in-backup-strategies/"
              >
                <p className="font-bold">
                  <i className="fa fa-angle-right text-2x text-primary mr-2"></i>
                  Understanding RTO and RPO in Backup Strategies
                </p>
              </a>
              <a
                className="bg-gray-50 rounded-lg p-6"
                href="/blog/what-is-saas-backup-and-why-do-you-need-it/"
              >
                <p className="font-bold">
                  <i className="fa fa-angle-right text-2x text-primary mr-2"></i>
                  What is SaaS Backup and Why Do You Need It?
                </p>
              </a>
              <a
                className="bg-gray-50 rounded-lg p-6"
                href="/blog/enhancing-cybersecurity-with-robust-backup-and-disaster-recovery-solutions/"
              >
                <p className="font-bold">
                  <i className="fa fa-angle-right text-2x text-primary mr-2"></i>
                  Enhancing Cybersecurity with Robust Backup
                </p>
              </a>
              <a
                className="bg-gray-50 rounded-lg p-6"
                href="/blog/backup-as-a-service-the-ultimate-solution-for-data-safety-you-need/"
              >
                <p className="font-bold">
                  <i className="fa fa-angle-right text-2x text-primary mr-2"></i>
                  Backup as a Service: The Ultimate Solution for Data Safety You
                  Need
                </p>
              </a>
            </div>
          </div>
        </Ui.Row>
      </Ui.Container>
    </Ui.Section>
  );
}

function StickyNavigation(context, setContext, visibleId) {
  return (
    <nav
      className="w-full top-0 sticky left-0 right-0 shadow-sm z-30 bg-white"
      style={{
        backdropFilter: "blur(28px)",
      }}
    >
      <div className="max-w-7xl mx-auto px-6 sm:px-6 py-6 flex justify-between items-center gap-16">
        <div className="text-lg text-warm-gray-500 flex flex-col md:flex-row gap-1 md:gap-3 md:items-center mx-auto lg:mx-0">
          <div className="uppercase text-xs text-gray-400 tracking-wide">
            In the lens of:
          </div>
          <select
            value={context}
            onChange={(e) => setContext(e.target.value)}
            className="block rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
          >
            {Object.keys(convincingContexts).map((k) => (
              <option value={k} key={k}>
                {convincingContexts[k]}
              </option>
            ))}
          </select>
        </div>
        <div className="hidden lg:flex text-base my-auto items-center justify-between gap-16 ">
          <a
            className={`cursor-pointer hover:text-primary ${
              visibleId == "understanding-baas"
                ? "text-primary border-b border-primary"
                : ""
            } `}
            href="#understanding-baas"
          >
            Understanding BaaS
          </a>
          <a
            className={`cursor-pointer hover:text-primary ${
              visibleId == "data-security-and-compliance"
                ? "text-primary border-b border-primary"
                : ""
            } `}
            href="#data-security-and-compliance"
          >
            Data Security & Compliance
          </a>
          <a
            className={`cursor-pointer hover:text-primary ${
              visibleId == "discover-simplebackups"
                ? "text-primary border-b border-primary"
                : ""
            } `}
            href="#discover-simplebackups"
          >
            Discover SimpleBackups
          </a>
        </div>
      </div>
    </nav>
  );
}

function AIGenerator() {
  return (
    <Ui.Section color="primary">
      <Ui.Container>
        <Ui.Row>
          <div className="col-md-12 text-center">
            <h1 className="font-heading text-4xl md:text-5xl leading-tight font-black text-dark">
              The ultimate "Convince my boss" Guide for backups!
            </h1>
            <p>
              You're in charge of defining what backup solution to use, and
              we'll help you convince whoever signs at the bottom!
            </p>
          </div>

          <div className="rounded-xl my-12 p-16 bg-aubergine stripe-container text-white relative text-center text-md">
            <div className="stripe stripe--aubergine" />
            <div className="relative">
              <Ui.Typography tag="h2" className=" ">
                Powered by AI!
              </Ui.Typography>
              <div className="mt-6">
                {/* Button to generate email. Handwritten, dear ... Burn some token chatgpt. For pricing we could do a fun app where it uses gpt to generate price of what his boss is into and contextualise it with the price of SB.
Like it's 2 lessons of tennis per year. It's 1/2 911 fuel tank. Saves the equivalent of 200 chess games in restore time...
Add Blog post references in this page (thumbnails)

*/}
                Yes, backup is critical, it will save your business and not cost
                you millions in GPU cycles.
                <br />
                But everything is not about you! <br />
                <br />
                We're geeks, and the frustrated kind. <br />
                We needed an excuse to use AI, so we've built{" "}
                <span className="link-highlight-dark bold">
                  the only AI powered convince my boss tool
                </span>{" "}
                you'll ever need.
                <br />
              </div>

              <div className="flex justify-center my-14">
                <Ui.Button
                  color="primary-200"
                  size="large"
                  className="shadow-xl"
                  href={loginUrl({
                    sb_source: "website",
                    sb_term: "navigation",
                  })}
                >
                  Discover AI Backup As A Service
                </Ui.Button>
              </div>

              <p className="mt-4 italic text-sm">
                We all know it won't save mankind but we'll be able to add
                "Power by AI" to our landing page, and it will help us shine at
                cocktail parties.
              </p>
            </div>
          </div>

          {/*<div className="bg-primary shadow-2xl rounded-xl p-6 text-white mt-24">
          We're geeks, and the frustrated kind. Yes backup is critical, it will save your business and not cost you
          millions in GPU cycle.
          But we have free credits to spend, and we needed an excuse to use AI, so we've built the only AI powered
          convince my boss tool you'll ever need. By needing, we all know you don't need it at all but we'll
          be able to add AI to our landing page somewhere, and finally get the peace we deserve.
        </div>*/}
          {TableOfContents()}
        </Ui.Row>
      </Ui.Container>
    </Ui.Section>
  );
}

function TableOfContents() {
  return (
    <div
      className={
        "m-auto max-w-4xl bg-gray-100 rounded-xl p-8 text-gray-600 text-base flex flex-col gap-3"
      }
    >
      <a className={"block"} href="#elevator-pitch">
        <span className={" border-b border-gray-400"}>The elevator pitch</span>
      </a>
      <div className={"border-t border-gray-200 mt-4 mb-4"}></div>
      <div className="uppercase text-sm text-gray-400 tracking-wide">
        Understanding BaaS
      </div>
      <a className={"block"} href="#WhyDoYouNeedABackupSolution">
        <span className={" border-b border-gray-400"}>
          Why you need a backup solution?
        </span>
      </a>
      <a className={"block ml-6"} href="#understand-threats">
        <span className={" border-b border-gray-400"}>
          Understand the threats and the risks you're facing
        </span>
      </a>
      <a className={"block ml-6 "} href="#why-you-to-backup">
        <span className={" border-b border-gray-400"}>
          Why you need to backup your data (per data type)
        </span>
      </a>
      <a className={"block ml-6 "} href="#how-is-your-data-secured">
        <span className={" border-b border-gray-400"}>
          How is your data secured, today?
        </span>
      </a>

      <a className={"block"} href="#understand-baas">
        <span className={" border-b border-gray-400"}>
          Understand what Backup-as-a-Service (BaaS) is and why you might need it
        </span>
      </a>
      <a className={"block ml-6"} href="#alternative-backup-solutions">
        <span className={" border-b border-gray-400"}>
          What are the alternative backup solutions?
        </span>
      </a>
      <a className={"block ml-6"} href="#key-features">
        <span className={" border-b border-gray-400"}>
          What are the key features you should be looking for in a BaaS
          solution?
        </span>
      </a>

      <a className={"block"} href="#obligations">
        <span className={" border-b border-gray-400"}>
          Your obligations when it comes to backup
        </span>
      </a>
      <a className={"block ml-6"} href="#obligations-agencies">
        <span className={" border-b border-gray-400"}>
          The case for Agencies and Development Shops
        </span>
      </a>
      <a className={"block ml-6"} href="#data-security-compliance">
        <span className={" border-b border-gray-400"}>
          Data Security, and compliance regulations with Backups
        </span>
      </a>

      <div className={"border-t border-gray-200 mt-4 mb-4"}></div>

      <div className="uppercase text-sm text-gray-400 tracking-wide">
        Data Security, Compliance at SimpleBackups
      </div>
      <a className={"block"} href="#data-security-compliance">
        <span className={" border-b border-gray-400"}>
          Data Security with SimpleBackups
        </span>
      </a>
      <a className={"block ml-6"} href="#encryption-transit-stored">
        <span className={" border-b border-gray-400"}>
          Encryption, transit, stored data
        </span>
      </a>
      <a className={"block ml-6"} href="#compliance-auditing">
        <span className={" border-b border-gray-400"}>
          Compliance and auditing
        </span>
      </a>
      <a className={"block ml-6"} href="#questions-concerns">
        <span className={" border-b border-gray-400"}>
          Questions and concerns
        </span>
      </a>

      <div className={"border-t border-gray-200 mt-4 mb-4"}></div>
      <div className="uppercase text-sm text-gray-400 tracking-wide">
        Discover SimpleBackups
      </div>
      <a className={"block"} href="#simplebackups-walkthrough">
        <span className={" border-b border-gray-400"}>
          SimpleBackups walkthrough
        </span>
      </a>
      <a className={"block ml-6"} href="#configuring-backup">
        <span className={" border-b border-gray-400"}>
          Configuring a backup
        </span>
      </a>
      <a className={"block ml-6"}>
        <span className={" border-b border-gray-400"}>Restoring a backup</span>
      </a>
      <a className={"block ml-6"} href="#restoring-backup">
        <span className={" border-b border-gray-400"}>
          Backup Recovery Plan and compliance board
        </span>
      </a>

      <a className={"block"} href="#real-world-use-cases">
        <span className={" border-b border-gray-400"}>
          Real-world use cases
        </span>
      </a>
      <a className={"block ml-6"} href="#saas-tech-team">
        <span className={" border-b border-gray-400"}>
          SaaS & Digital Product backup
        </span>
      </a>
      <a className={"block ml-6"} href="#agency_backup">
        <span className={" border-b border-gray-400"}>
          Agency project based backups
        </span>
      </a>
      <a className={"block ml-6"} href="#geek">
        <span className={" border-b border-gray-400"}>
          Geeks securing their hobby project
        </span>
      </a>
    </div>
  );
}
/*
*
            <div className="mt-24 m-auto max-w7xl">
              <div className={"flex items-center gap-12"}>
                <div className={"text-bold font-bold w-1/3"}>Who do you need to convince?</div>
                <div className={"flex gap-2"}>
                  <div className={"p-4 bg-gray-100 rounded-lg"}>CEO</div>
                  <div className={"p-4 rounded-lg bg-primary text-white"}>CTO</div>
                  <div className={"p-4 bg-gray-100 rounded-lg"}>Yourself</div>
                  <div className={"p-4 bg-gray-100 rounded-lg"}>Client</div>
                </div>
              </div>

              <div className={"flex mt-6 items-center gap-12"}>
                <div className={"text-bold font-bold w-1/3"}>What is your industry?</div>
                <div className={"flex gap-2"}>
                  <div className={"p-4 rounded-lg bg-primary text-white"}>SaaS</div>
                  <div className={"p-4 bg-gray-100 rounded-lg"}>Agency</div>
                  <div className={"p-4 bg-gray-100 rounded-lg"}>Service</div>
                  <div className={"p-4 bg-gray-100 rounded-lg"}>Other</div>
                </div>
              </div>

              <div className={"flex mt-6 items-center gap-12"}>
                <div className={"text-bold font-bold w-1/3"}>What is you company size?</div>
                <div className={"flex gap-2"}>
                  <div className={"p-4 rounded-lg bg-primary text-white"}>2-10</div>
                  <div className={"p-4 bg-gray-100 rounded-lg"}>11-50</div>
                  <div className={"p-4 bg-gray-100 rounded-lg"}>50-200</div>
                  <div className={"p-4 bg-gray-100 rounded-lg"}>200+</div>
                </div>
              </div>

              <div className={"flex mt-6 items-center gap-12"}>
                <div className={"text-bold font-bold w-1/3"}>What do you plan on backing up?</div>
                <div className={"flex gap-2"}>
                  <div className={"p-4 rounded-lg bg-primary text-white"}>Databases</div>
                  <div className={"p-4 bg-gray-100 rounded-lg"}>Servers</div>
                  <div className={"p-4 bg-gray-100 rounded-lg"}>Storage</div>
                  <div className={"p-4 bg-gray-100 rounded-lg"}>Apps</div>
                </div>
              </div>
            </div>
*/
